import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import {
  AllSelectedFilters,
  ProductFacade,
  ProductFilterService,
  ProductGroupFilterService,
  ProductIndustryFilterService,
} from '@ev-portals/dp/frontend/product/data-access';
import {
  NavigatorFilterIdEnum,
  ProductDto,
  ProductGroupFilterIdEnum,
} from '@ev-portals/dp/frontend/shared/api-client';
import { AnalyticsService } from '@ev-portals/dp/frontend/shared/util';
import { PaginatorComponent, ShimmerEffectDirective } from '@ev-portals/ev/frontend/ui-library';
import { map as _map } from 'lodash';
import { combineLatest } from 'rxjs';

import { ProductListService, SortingOption, SortingType } from './product-list.service';
import { ProductlistItemComponent } from './product-list-item';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ProductlistItemComponent,
    PaginatorComponent,
    AngularModule,
    FormsModule,
    ShimmerEffectDirective,
  ],
  providers: [ProductListService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent implements OnDestroy {
  #productFilterService = inject(ProductFilterService);
  #productFacade = inject(ProductFacade);
  #productListService = inject(ProductListService);
  #analyticsService = inject(AnalyticsService);
  #productIndustryCategoryService = inject(ProductIndustryFilterService);
  #productGroupFilterService = inject(ProductGroupFilterService);

  // Means, somebody did a meaningful action on a certain product (click on details, place order, etc.)
  @Output() clickProductDetails = new EventEmitter<ProductDto>();

  $loading = this.#productListService.$loading;

  sortLabel = 'Sort by';
  sortingOptions: SortingOption[] = [
    { type: 'promoted', title: 'Promoted' },
    { type: 'az', title: 'A-Z' },
    { type: 'za', title: 'Z-A' },
    { type: 'relevance', title: 'Relevance' },
  ];
  sortingDropdownModel: SortingType;

  $visibleProducts = this.#productListService.$visibleProducts;
  PAGE_SIZE = this.#productListService.PAGE_SIZE;
  $paginatorData = this.#productListService.$paginatorData;
  $numberOfFilteredProducts = this.#productListService.$numberOfFilteredProducts;
  productToBuy: ProductDto | null = null;

  // For tracking. Keeps synced, but gets cleared if product is found
  #filtersBeforeProductFound: AllSelectedFilters | null = null;

  constructor() {
    this.#syncFiltersBeforeProductFound();

    effect(() => {
      if (this.sortingDropdownModel !== this.#productListService.$sortingType()) {
        this.sortingDropdownModel = this.#productListService.$sortingType();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.#filtersBeforeProductFound) {
      this.#analyticsService.trackEvent(
        'product-list',
        'productSearchNotFound',
        'action',
        JSON.stringify(this.#filtersBeforeProductFound),
      );
    }
  }

  #syncFiltersBeforeProductFound(): void {
    combineLatest([
      this.#productFilterService.selectedLocalFilters$,
      this.#productFacade.selectedRemoteFilters$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([localFilters, remoteFilters]) => {
        this.#filtersBeforeProductFound = this.#getFiltersForTracking({
          ...localFilters,
          ...remoteFilters,
        });
      });
  }

  #trackEventForProductAction(product: ProductDto): void {
    if (this.#filtersBeforeProductFound) {
      this.#analyticsService.trackEvent(
        'product-list',
        'productSearchFound',
        'action',
        JSON.stringify(this.#filtersBeforeProductFound),
      );
      console.log('FOUND', this.#filtersBeforeProductFound);
      this.#filtersBeforeProductFound = null;
    }
  }

  onSortingChange(newSortingType: SortingType): void {
    this.#productListService.changeSortingType(newSortingType);
  }

  onClickProductDetails(product: ProductDto): void {
    this.clickProductDetails.emit(product);
    this.#trackEventForProductAction(product);
  }

  onActivatePage(pageIndex: number): void {
    this.#productListService.activatePage(pageIndex);
  }

  #getFiltersForTracking(allSelectedFilters: AllSelectedFilters): AllSelectedFilters {
    return Object.fromEntries(
      _map(allSelectedFilters, (filterValue, filterId) => {
        // if filter is industries&applications or productGroupFilter we need the option name instead of the option id
        if ((Object.values(NavigatorFilterIdEnum) as string[]).includes(filterId)) {
          filterValue = this.#productIndustryCategoryService.getOptionName(filterValue as string);
        }

        if (
          Object.values(ProductGroupFilterIdEnum).includes(filterId as ProductGroupFilterIdEnum)
        ) {
          filterValue = this.#productGroupFilterService.getProductGroupOptionTitle(
            filterId as ProductGroupFilterIdEnum,
            filterValue as string,
          );
        }

        return [filterId, filterValue];
      }),
    );
  }
}

export type ProductActionType = 'productDetails';

export interface ProductAction {
  actionType: ProductActionType;
  product: ProductDto;
}
