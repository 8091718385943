import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { AtomsOptionEvent } from '@ev-portals/dp/frontend/product/data-access';
import { AccordionComponent, ChipComponent } from '@ev-portals/ev/frontend/ui-library';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ReactiveFormsModule,
    ChipComponent,
    AccordionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-single-multi-select-filter',
  templateUrl: 'single-multi-select-filter.component.html',
  styleUrls: ['./single-multi-select-filter.component.scss'],
})
export class SingleMultiSelectFilterComponent implements OnInit, OnChanges {
  #destroyRef = inject(DestroyRef);

  @Input() title: string;
  @Input() options!: InputOption[];
  @Input() hintText = '';
  @Input() initialSelection: string[] = [];
  @Input() disabled = false;
  @Input() selectType: SelectType;
  @Input() reset$!: Observable<void>;
  @Output() changeSelection = new EventEmitter<string[]>();
  groupControl = new UntypedFormControl();
  countSelections = 0;
  isOpened = false;

  // TODO refactor use of getters
  public get selectedOptions(): InputOption[] {
    const selectedOptions = this.selectedOptionIds?.map(optionId => {
      return this.options.find(option => option.id === optionId) as InputOption;
    });
    return selectedOptions;
  }

  // groupControl.value could be string or string[]
  public get selectedOptionIds(): string[] {
    return this.#stringToArray(this.groupControl?.value);
  }

  public get visibleSelections(): InputOption[] {
    let showSelections = [];

    if (this.selectedOptions.length > 3) {
      showSelections = this.selectedOptions.slice(0, 3);
      return showSelections;
    } else return this.selectedOptions;
  }

  ngOnInit(): void {
    this.#initLocalState();
    this.#setupResetListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When the filter box gets disabled, silently clear the filter selections
    if (
      changes[`disabled`]?.previousValue === false &&
      changes[`disabled`]?.currentValue === true
    ) {
      this.#clearAllSelection();
    }

    this.#handleChangedOptions(changes);
  }

  /**
   * Deselects radio option if it was already selected
   */
  onClickRadioButton(option: InputOption): void {
    if (this.groupControl.value === option.id) {
      // setTimeout is needed, to overrule atoms.. (not ideal)
      setTimeout(() => {
        this.#clearAllSelection(true);
      }, 0);
    }
  }

  #handleChangedOptions(changes: SimpleChanges): void {
    const newOptions: InputOption[] = changes[`options`]?.currentValue ?? [];
    const newOptionIds: string[] = newOptions.map(option => option.id);
    const prevOptions: InputOption[] = changes[`options`]?.previousValue ?? [];
    const prevOptionIds: string[] = prevOptions.map(option => option.id);

    // Find the options, which are not available anymore:
    const removedOptionIds = prevOptionIds.filter(optionId => !newOptionIds.includes(optionId));
    if (removedOptionIds.length > 0) {
      this.#processNewOptions(newOptions);
    }
  }

  // Init the local state
  #initLocalState(): void {
    if (this.selectType === 'radio') {
      this.groupControl.setValue(this.initialSelection[0]);
    }

    if (this.selectType === 'checkbox') {
      // TODO: Ugly hack, for toggle filters, it should be handled later in a different dedicated component
      if (typeof this.initialSelection === 'boolean') {
        this.initialSelection = this.initialSelection ? [this.options[0].id] : [];
      }
      this.groupControl.setValue(this.initialSelection);
    }
  }

  trackById(index: number, objectWithId: { id: string }): string {
    return objectWithId.id;
  }

  #stringToArray(value: undefined | string | string[]): string[] {
    if (!value) {
      // it's undefined
      return [];
    }

    if (typeof value === 'string') {
      return [value];
    }

    return [...value];
  }

  onChangeByUser(event: AtomsOptionEvent): void {
    const selectedIds = this.#stringToArray(this.groupControl.value);
    this.#emitNewSelection(selectedIds);
  }

  #emitNewSelection(selectedIds: string[]): void {
    this.changeSelection.emit(selectedIds);
  }

  /**
   * Here we react on options changes, and remove the selections, which are not available anymore
   * @param newOptions
   */
  #processNewOptions(newOptions: InputOption[]): void {
    const visibleOptionsSet = new Set(newOptions.map(option => option.id));
    const newSelectedIds = this.selectedOptionIds.filter(selectedId =>
      visibleOptionsSet.has(selectedId),
    );

    if (newSelectedIds.length !== this.selectedOptionIds.length) {
      this.#updateSelectedIds(newSelectedIds);
    }
  }

  /**
   * A unified method to update selectedIds, for 'radio' and 'checkbox' selectType as well
   * These changes are 'silent' changes, which aren't caused by the user
   * @param stringArray
   */
  #updateSelectedIds(stringArray: string[], emitChange = false): void {
    let newValue: string | string[] | undefined = stringArray;
    if (this.selectType === 'radio') {
      newValue = stringArray[0] ?? null;
    }

    this.groupControl.setValue(newValue);
    if (emitChange) {
      this.#emitNewSelection(stringArray);
    }
  }

  onRemoveSelectedId(optionId: string): void {
    const newSelectedIds = this.selectedOptionIds.filter(id => id !== optionId);
    this.#updateSelectedIds(newSelectedIds, true);
  }

  /**
   * Here we react on reset requests
   */
  #setupResetListener(): void {
    if (!this.reset$) {
      throw new Error(
        `[ProductFilterBoxComponent::setupResetListener] @Input reset$ is not defined!`,
      );
    }

    this.reset$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      // We don't emit new selection as this was triggered (aka controlled) by the parent
      this.#clearAllSelection();
      this.isOpened = false;
    });
  }

  #clearAllSelection(emitChange = false): void {
    this.#updateSelectedIds([], emitChange);
  }
}

type SelectType = 'radio' | 'checkbox';

export interface InputOption {
  id: string;
  title: string;
}
