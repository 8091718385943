<div class="product-list-item p-3 sm:p-6 xl:p-8 flex gap-4 max-sm:flex-col">
  <div
    class="left-block grow flex gap-2 justify-between max-sm:flex-col sm:items-center"
  >
    <a
      [evShimmerEffect]="shimmerEffect"
      class="product-name no-change-link cursor-pointer"
      [routerLink]="productUrl"
      (click)="onProductClick()"
    >
      {{ product.name }}
    </a>
    @if (product.minPrice) {
      <div class="min-price" [evShimmerEffect]="shimmerEffect">
        <span>Available from </span>
        {{ product.minPrice | evPrice }}
      </div>
    }
  </div>

  <div class="action-buttons grid gap-2 sm:gap-3 xl:gap-4 grid-flow-col">
    <a
      [evShimmerEffect]="shimmerEffect"
      [routerLink]="productUrl"
      (click)="onProductClick()"
      class="more-details-button no-change-link"
    >
      <atom-button><span>More Details</span></atom-button>
    </a>
  </div>
</div>
