import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { ProductFilterService } from '@ev-portals/dp/frontend/product/data-access';
import {
  ProductGroupFilterIdEnum,
  ProductGroupFilterOption,
} from '@ev-portals/dp/frontend/shared/api-client';
import { SelectedRemoteFilters } from '@ev-portals/dp/frontend/shared/util';
import { Observable } from 'rxjs';

import { SingleMultiSelectFilterComponent } from '../single-multi-select-filter';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ReactiveFormsModule,
    SingleMultiSelectFilterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-group-filter',
  templateUrl: 'product-group-filter.component.html',
  styleUrls: ['./product-group-filter.component.scss'],
})
export class ProductGroupFilterComponent {
  #productFilterService = inject(ProductFilterService);

  @Input() id!: string;
  @Input() options: ProductGroupFilterOption[];
  @Input() initialSelection: string[] = [];
  @Input() reset$!: Observable<void>;

  titleMap: Record<string, string> = {
    [ProductGroupFilterIdEnum.PolymersFilter]: 'Polymers',
    [ProductGroupFilterIdEnum.FunctionsFilter]: 'Functions',
  };

  hintTextMap: Record<string, string> = {
    [ProductGroupFilterIdEnum.PolymersFilter]: '',
    [ProductGroupFilterIdEnum.FunctionsFilter]: '',
  };

  filterTypeMap: Record<string, 'radio' | 'checkbox'> = {
    [ProductGroupFilterIdEnum.PolymersFilter]: 'radio',
    [ProductGroupFilterIdEnum.FunctionsFilter]: 'radio',
  };

  onChangeSelection(changeSelection: string[]): void {
    this.#productFilterService.changeRemoteFiltersByKey(
      this.id as keyof SelectedRemoteFilters,
      this.filterTypeMap[this.id] === 'radio' ? changeSelection[0] : changeSelection,
    );
  }

  trackById(index: number, objectWithId: { id: string }): string {
    return objectWithId.id;
  }
}
