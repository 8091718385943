import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CacheSearchService,
  ProductFilterService,
  SearchQueryParams,
} from '@ev-portals/dp/frontend/product/data-access';
import { ProductDto } from '@ev-portals/dp/frontend/shared/api-client';
import { LayoutService } from '@ev-portals/ev/frontend/util';

import { ProductFiltersComponent } from './product-filters';
import { ProductListComponent } from './product-list';

@Component({
  standalone: true,
  imports: [CommonModule, ProductListComponent, ProductFiltersComponent],
  providers: [CacheSearchService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchComponent {
  #layoutService = inject(LayoutService);
  #route = inject(ActivatedRoute);
  #cacheSearchService = inject(CacheSearchService);
  #productFilterService = inject(ProductFilterService);

  isSmallerThanLarge$ = this.#layoutService.isSmallerThanLarge$;
  filteredProducts$ = this.#productFilterService.filteredProducts$;

  constructor() {
    // this.syncFiltersBeforeProductFound();
    this.#cacheSearchService.syncQueryParams(this.#route.snapshot.queryParams as SearchQueryParams);
  }

  // public onClickProductAction(productAction: ProductAction): void {
  onClickProductDetails(product: ProductDto): void {
    console.log('onClickProductAction - Not implemented yet');
    // TODO: Here we could add event tracking if needed, but the navigation is handled within the product-list-item using an <a> tag
  }

  // TODO: add later
  // private analyticsService = inject(AnalyticsService);
  // // For tracking. Keeps synced, but gets cleared if product is found
  // private filtersBeforeProductFound: AllSelectedFilters | null = null;
  // ngOnDestroy(): void {
  //   if (this.filtersBeforeProductFound) {
  //     this.analyticsService.trackEvent(
  //       'product-list',
  //       'productSearchNotFound',
  //       'action',
  //       JSON.stringify(this.filtersBeforeProductFound)
  //     );
  //   }
  // }
  // private syncFiltersBeforeProductFound(): void {
  //   this.productFilterService.allSelectedFilters$
  //     .pipe(untilDestroy$(this))
  //     .subscribe((filters) => {
  //       this.filtersBeforeProductFound = this.getFiltersForTracking(filters);
  //     });
  // }
}
