import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { ProductFilterService } from '@ev-portals/dp/frontend/product/data-access';
import { first, Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, AngularModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-text-search',
  templateUrl: './product-text-search.component.html',
  styleUrls: ['./product-text-search.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTextSearchComponent {
  #destroyRef = inject(DestroyRef);

  @Input() reset$: Observable<void>;

  searchTermModel = '';
  textSearchPlaceholder = 'Type to search...';

  constructor(
    private productFilterService: ProductFilterService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.#setupResetListener();
    // Init the local model
    this.productFilterService.allSelectedFilters$.pipe(first()).subscribe(filters => {
      this.searchTermModel = filters.SearchFilter ?? '';
      this.changeDetectorRef.detectChanges();
    });
  }

  onSearchTermUpdate(): void {
    this.productFilterService.changeSearchTerm(this.searchTermModel);
  }

  #setupResetListener(): void {
    this.reset$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.#resetSearchTerm();
    });
  }

  #resetSearchTerm(): void {
    this.searchTermModel = '';
  }
}
