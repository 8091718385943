import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularModule } from '@atoms/angular';
import { AccordionComponent, ChipComponent } from '@ev-portals/ev/frontend/ui-library';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ReactiveFormsModule,
    ChipComponent,
    AccordionComponent,
    MatSlideToggleModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-toggle-filter',
  templateUrl: 'product-toggle-filter.component.html',
  styleUrls: ['./product-toggle-filter.component.scss'],
})
export class ProductToggleFilterComponent implements OnInit {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() filterInfo!: FilterInfo;
  @Input() reset$!: Observable<void>;
  @Output() toggle = new EventEmitter<boolean>();

  groupControl = new UntypedFormControl();
  countSelections = 0;
  isOpened = false;

  ngOnInit(): void {
    this.#setupResetListener();
  }

  onToggleChange(toggleChange: MatSlideToggleChange): void {
    this.toggle.emit(toggleChange.checked);
  }

  /**
   * Here we react on reset requests
   */
  #setupResetListener(): void {
    if (!this.reset$) {
      throw new Error(
        `[${ProductToggleFilterComponent.name}::setupResetListener] @Input reset$ is not defined!`,
      );
    }

    this.reset$.pipe(takeUntilDestroyed()).subscribe(() => {
      // We don't emit new selection as this was triggered (aka controlled) by the parent
      // TODO: set value to false
      this.isOpened = false;
    });
  }
}

interface FilterInfo {
  title: string;
  hintText?: string;
}
