<div class="title-bar flex items-center justify-between">
  <!-- Mobile: This is a button on mobile, to show / hide the filters -->
  <ng-container *ngTemplateOutlet="filtersToggleButton"></ng-container>

  <!-- Title: show the title 'Filters' -->
  <div class="filters-title hidden lg:block">Filters</div>
  @if (numberOfFilters$ | async) {
    <div
      class="clear-all-text cursor-pointer hover:underline"
      (click)="onResetAllFilters()"
    >
      Clear Filters
    </div>
  }
</div>

<!-- Conditional mobile view, depends on the toggle state -->
@if (showFiltersOnMobile) {
  <div class="mobile-container lg:hidden">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </div>
}

<!-- Unconditional desktop view, always show the filters -->
<div class="desktop-container hidden lg:block">
  <ng-container *ngTemplateOutlet="filters"></ng-container>
</div>

<ng-template #filtersToggleButton>
  <atom-button
    class="lg:hidden"
    [attr.secondary]="showFiltersOnMobile ? null : true"
    (click)="showFiltersOnMobile = !showFiltersOnMobile"
  >
    <atom-icon icon-id="atom:basf:filter" slot="left"></atom-icon>
    <ng-container>
      {{ showFiltersOnMobile ? hideFiltersText : showFiltersText }}
    </ng-container>
    @if (numberOfFilters$ | async; as numberOfFilters) {
      {{ numberOfFilters ? "(" + numberOfFilters + ")" : "" }}
    }
  </atom-button>
</ng-template>

<ng-template #filters>
  @if (selectedFilters$ | async; as selectedFilters) {
    <div class="filter-boxes grid gap-4 mt-4">
      @if (availableFilters$ | async; as availableFilters) {
        @for (filter of availableFilters; track trackById($index, filter)) {
          <!-- First we find appropriate filters by id -->
          <ng-container>
            @if (filter.id === filterIdEnum.SearchFilter) {
              <dp-product-text-search
                [reset$]="resetFilters$"
              ></dp-product-text-search>
            }

            @if (filter.id === filterIdEnum.IndustryFilter) {
              <dp-industries-navigator
                [reset$]="resetFilters$"
              ></dp-industries-navigator>
            }

            <!-- TODO! remove plastics from ngIf when PIM renames category -->
            @if (isProductGroupFilterId(filter.id)) {
              <dp-product-group-filter
                [id]="filter.id"
                [reset$]="resetFilters$"
                [options]="filter['options']!"
                [initialSelection]="$any([selectedFilters[filter.id]])"
              ></dp-product-group-filter>
            }
          </ng-container>
        }
      } @else {
        <span>Filters are loading, please wait.</span>
      }
    </div>
  }
</ng-template>
