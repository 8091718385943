<dp-single-multi-select-filter
  [title]="titleMap[id]"
  [hintText]="hintTextMap[id]"
  [options]="options"
  [initialSelection]="initialSelection"
  [selectType]="filterTypeMap[id]"
  [reset$]="reset$"
  (changeSelection)="onChangeSelection($event)"
>
</dp-single-multi-select-filter>
