import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProductDto } from '@ev-portals/dp/frontend/shared/api-client';
import { ShimmerEffectDirective } from '@ev-portals/ev/frontend/ui-library';
import { PricePipeModule } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, ShimmerEffectDirective, PricePipeModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductlistItemComponent {
  @Input() product: ProductDto;
  @Input() shimmerEffect = false;

  @Output() clickProductDetails = new EventEmitter<ProductDto>();

  public get productUrl(): string[] {
    return ['/', 'products', this.product.slug, 'p', this.product.id];
  }

  onProductClick(): void {
    this.clickProductDetails.emit(this.product);
  }
}
