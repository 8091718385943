import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import { FilterIdEnum, ProductFilterService } from '@ev-portals/dp/frontend/product/data-access';
import {
  ProductGroupFilterIdEnum,
  ProductGroupFilterOption,
} from '@ev-portals/dp/frontend/shared/api-client';
import { LayoutService } from '@ev-portals/ev/frontend/util';
import { Observable } from 'rxjs';

import { IndustriesNavigatorComponent } from './industries-navigator';
import { ProductGroupFilterComponent } from './product-group-filter';
import { ProductTextSearchComponent } from './product-text-search';
import { ProductToggleFilterComponent } from './product-toggle-filter';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ProductGroupFilterComponent,
    ProductTextSearchComponent,
    IndustriesNavigatorComponent,
    ProductToggleFilterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-product-filters',
  templateUrl: './product-filters.component.html',
  styleUrls: ['./product-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFiltersComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  hideFiltersText = 'Hide filters';
  showFiltersText = 'Show filters';
  availableFilters$ = this.productFilterService.availableFilters$ as Observable<
    Array<{
      id: `${FilterIdEnum}`;
      options?: ProductGroupFilterOption[];
    }>
  >;
  selectedFilters$ = this.productFilterService.allSelectedFilters$;
  numberOfFilters$ = this.productFilterService.numberOfSelectedFilters$;
  showFiltersOnMobile = false;
  isSmallerThanLarge$ = this.layoutService.isSmallerThanLarge$;
  resetFilters$ = this.productFilterService.resetFilters$;
  filterIdEnum = FilterIdEnum;

  constructor(
    private productFilterService: ProductFilterService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.resetFilters$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.showFiltersOnMobile = false;
    });
  }

  onResetAllFilters(): void {
    // Reset the local UI
    this.productFilterService.resetView();
    // Reset the state and refresh results
    this.productFilterService.resetFilters();
  }

  trackById(index: number, objectWithId: { id: string }): string {
    return objectWithId.id;
  }

  isProductGroupFilterId(filterId: string): boolean {
    return Object.values(ProductGroupFilterIdEnum).includes(filterId as ProductGroupFilterIdEnum);
  }
}
