import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProductFilterService } from '@ev-portals/dp/frontend/product/data-access';
import { mockProduct, ProductDto } from '@ev-portals/dp/frontend/shared/api-client';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  #productFilterService = inject(ProductFilterService);

  // Constants
  PAGE_SIZE = 20;

  // Source signals
  #$filteredProducts = toSignal<ProductDto[], ProductDto[]>(
    this.#productFilterService.filteredProducts$,
    // Start with mock data, so we can use the shimmer effect
    {
      initialValue: Array(this.PAGE_SIZE + 1).fill(mockProduct) as ProductDto[],
    },
  );
  $numberOfFilteredProducts = computed(() => this.#$filteredProducts().length);

  // Paginator info
  $activePageIndex = signal(0);
  $numberOfPages = computed(() => {
    const numberOfPages = Math.ceil(this.#$filteredProducts().length / this.PAGE_SIZE);

    // If the active page is not available, jump to the first page
    if (this.$activePageIndex() !== 0 && this.$activePageIndex() >= numberOfPages) {
      this.activatePage(0);
    }

    return numberOfPages;
  });

  $paginatorData = computed(() => ({
    activePageIndex: this.$activePageIndex(),
    numberOfPages: this.$numberOfPages(),
  }));

  $loading = this.#productFilterService.loadingProductList;

  // Sorting info
  defaultSortingType: SortingType = 'promoted';
  $sortingType = signal(this.defaultSortingType);
  $sortedProducts = computed(() => this.#doSorting(this.$sortingType(), this.#$filteredProducts()));

  // Handle pagination
  $visibleProducts = computed(() =>
    this.$sortedProducts().slice(
      this.$activePageIndex() * this.PAGE_SIZE,
      this.$activePageIndex() * this.PAGE_SIZE + this.PAGE_SIZE,
    ),
  );

  constructor() {
    this.#handleSeachTermChanges();
  }

  #handleSeachTermChanges(): void {
    this.#getSearchTerm$().subscribe((searchTerm: any) => {
      // if we have a search term, we should sort by relevance
      if (searchTerm) {
        this.changeSortingType('relevance');
      }
    });
  }

  activatePage(pageIndex: number): void {
    this.$activePageIndex.set(pageIndex);
  }

  changeSortingType(sortingType: SortingType): void {
    this.$sortingType.set(sortingType);
    this.$activePageIndex.set(0);
  }

  #getSearchTerm$(): Observable<string | undefined> {
    return this.#productFilterService.selectedLocalFilters$.pipe(
      map(localFilters => localFilters.SearchFilter),
      distinctUntilChanged(),
    );
  }

  #doSorting(sortingType: SortingType, filteredProducts: ProductDto[]): ProductDto[] {
    switch (sortingType) {
      case 'az':
        return [...filteredProducts].sort((p1, p2) => (p1.name > p2.name ? 1 : -1));
      case 'za':
        return [...filteredProducts].sort((p1, p2) => (p1.name < p2.name ? 1 : -1));
      case 'promoted':
        // A-Z sorting + promoted sorting
        return [...filteredProducts]
          .sort((p1, p2) => (p1.name > p2.name ? 1 : -1))
          .sort((p1, p2) => {
            return p1.minPrice ? -1 : 1;
          });
      default:
        return filteredProducts;
    }
  }
}

export type SortingType = 'promoted' | 'relevance' | 'az' | 'za';

export interface SortingOption {
  type: SortingType;
  title: string;
}

export interface PaginatorData {
  numberOfPages: number;
  activePageIndex: number;
}
