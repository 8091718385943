<atoms-input
  data-cy="product-text-search"
  [placeholder]="textSearchPlaceholder"
  atomsControl2
  [(ngModel)]="searchTermModel"
  (keyup)="onSearchTermUpdate()"
  label=""
  [disabled]="false"
>
</atoms-input>
