import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  ProductFilterService,
  ProductIndustryFilterService,
} from '@ev-portals/dp/frontend/product/data-access';
import { NavigatorFilterIdEnum } from '@ev-portals/dp/frontend/shared/api-client';
import { AccordionComponent } from '@ev-portals/ev/frontend/ui-library';
import { Observable } from 'rxjs';

@Component({
  selector: 'dp-industries-navigator',
  standalone: true,
  imports: [CommonModule, AccordionComponent, MatProgressBarModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './industries-navigator.component.html',
  styleUrls: ['./industries-navigator.component.scss'],
})
export class IndustriesNavigatorComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  #industriesCategoryService = inject(ProductIndustryFilterService);
  #productFilterService = inject(ProductFilterService);
  #rootCategoryId = this.#industriesCategoryService.rootCategoryId;

  @Input() reset$: Observable<void>;

  $visibleCategory = this.#industriesCategoryService.$visibleCategory;
  isOpened = false;

  ngOnInit(): void {
    this.reset$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.isOpened = false;
      this.#productFilterService.changeRemoteFiltersByKey(
        NavigatorFilterIdEnum.IndustryFilter,
        null,
      );
    });
  }

  onSelectIndustryCategory($event: MouseEvent, categoryId: string | null | undefined): void {
    if (categoryId) {
      $event.stopPropagation();

      if (categoryId === this.#rootCategoryId) {
        // remove filter if we're in the root category to get same amount of products as default when navigating back to the top
        this.#productFilterService.changeRemoteFiltersByKey(
          NavigatorFilterIdEnum.IndustryFilter,
          null,
        );
      } else {
        this.#productFilterService.changeRemoteFiltersByKey(
          NavigatorFilterIdEnum.IndustryFilter,
          categoryId,
        );
      }
    }
  }
}
